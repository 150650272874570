<template>
    <b-row>
        <b-col>
            <FindObject render="search"
                        type="priceList" 
                        @select-object="loadPriceList($event)" 
                        :valueID="crud.form.price_list_id"                        
                        :key="componentKey"
                        :disabled="isSeller"/>                  
        </b-col>
            
        <b-col lg="12">            
            <b-button variant="dark" class="pull-right" size="sm" @click="save()" v-if="!isSeller">Guardar</b-button>
        </b-col>
    </b-row>
</template>
<script>
    import serviceAPI from './../services'
    import Error from '@/handler/error'
    import Session from '@/handler/session'
    import Profiles from '@/config/profiles'
    import Modules from '@/config/modules'
    import Helper from '@/handler/helper'    
    import FindObject from '@/components/inc/find/findObject'  

    export default {
        components: {
            FindObject,        
        },        
        props: [
            'customers',
            'customersID',
        ],
        data() {
            return {
                crud: {
                    form: {
                        id: 0,
                        customers_id: 0,
                        price_list: null,
                        price_list_id: 0
                    }
                },
                componentKey: 0,
            }
        },
        watch: {        
            customers: function (newQuestion, oldQuestion) {
                this.load()
            }
        },      
        computed: {
          isSeller() {
            var seller = Helper.getSeller()
            if(seller) {
              return true
            } else {
              return false
            }
          },  
        },          
        methods: {
            load() {
                if(this.customers.price_list){
                    this.crud.form.id = this.customers.price_list.id,
                    this.crud.form.customers_id = this.customers.price_list.customers_id,
                                                        
                    this.crud.form.price_list = this.customers.price_list.price_list                  
                    this.crud.form.price_list_id = this.customers.price_list.price_list_id     
                    
                    this.forceRerender()        
                } else {
                    this.crud.form.customers_id = this.customersID                    
                }
            },
            
            
            save() {
                var typeOperation = ''
                let loader = this.$loading.show();                

                if(!this.crud.form.price_list_id){
                    typeOperation = 'delete'
                    var result = serviceAPI.eliminarPricesLists(this.crud.form.id);
                } else {        
                    if(this.crud.form.id) {
                        typeOperation = 'edit'
                        var result = serviceAPI.editarPricesLists(this.crud.form);
                    } else {
                        typeOperation = 'save'
                        var result = serviceAPI.agregarPricesLists(this.crud.form);
                    }                    
                }                

                result.then((response) => {                    
                    loader.hide()
                    this.$awn.success("Datos guardados con éxito");

                    if(typeOperation != 'delete') {
                        this.crud.form.id = response.data.id
                    } else {
                        this.crud.form.id = 0
                    }
                })
                .catch(error => {
                    loader.hide()
                    this.$awn.alert(Error.showError(error));
                })
            },

            loadPriceList (object) {
                if(object){
                    this.crud.form.price_list = object
                    this.crud.form.price_list_id = object.id             
                } else {
                    this.crud.form.price_list = null
                    this.crud.form.price_list_id = 0
                }
            },    
            forceRerender() {
                this.componentKey += 1;  
            },             
        }
    }
</script>