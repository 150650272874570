<template>
    <b-row>
        <b-col sm="12">
            <b-card header-tag="header" footer-tag="footer">
                <b-row>
                    <b-col sm="7">
                        <h4 id="traffic" class="card-title mb-0">Contactos</h4>                        
                    </b-col>
                
                    <b-col sm="5">
                        <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Contacto" v-if="!isSeller">
                            <i class="fa fa-plus"></i> Agregar
                        </b-button>
                    </b-col>
                </b-row>            
                <b-row class="mt-3">
                    <b-col>
                        <b-table class="mb-0 crud-customers-contacts-table"
                                responsive="sm"
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="table.items"
                                :fields="table.fields"                                
                                v-if="table.items.length">                       

                            <template v-slot:table-colgroup="scope">
                                <col
                                    v-for="field in scope.fields"                    
                                    :key="field.key"
                                    :style="{ width: field.width }"
                                >
                            </template> 

                            <template v-slot:cell(name)="data">                            
                                <small v-if="data.item.market_stall" class="crud-customers-contacts-table-market-stall">
                                    {{data.item.market_stall}}<br>
                                </small>
                                <div class="crud-customers-contacts-table-name">
                                    {{data.item.name}}
                                    <span v-if="data.item.observations" class="crud-customers-contacts-table-observations">
                                        <b-icon icon="info-circle" v-b-tooltip.hover :title="data.item.observations"></b-icon>
                                    </span>                                    
                                </div>                                
                            </template>

                            <template v-slot:cell(contacts)="data">                                                            
                                <b-icon icon="envelope" v-if="data.item.email"></b-icon> 
                                <b-icon icon="blank" v-if="data.item.email"></b-icon> 
                                <b-link :href="'mailto:' + data.item.email">{{data.item.email}}</b-link>
                                <br>
                                <b-icon icon="phone" v-if="data.item.phone"></b-icon> 
                                <b-icon icon="blank" v-if="data.item.phone"></b-icon> 
                                <b-link :href="'tel:' + data.item.phone">{{data.item.phone}}</b-link>
                            </template>

                            <template v-slot:cell(f_action)="data">
                                <b-dropdown right text="" size="sm" variant="outline-dark" class="pull-right" v-if="!isSeller">
                                    <b-dropdown-item @click="edit(data.item)">
                                        <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="remove(data.item)">
                                        <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                    </b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                    </b-col>
                </b-row>            
            </b-card>

            <!-- ########################### -->
            <!-- #####     MODALES     ##### -->
            <!-- ########################### -->

            <!-- CRUD MAIN -->
            <b-modal v-model="modal.form.active"
                    header-bg-variant="dark"
                    header-text-variant="white">
                <div slot="modal-header">
                    {{this.modal.form.title}}
                </div>

                <b-row>
                    <b-col md="12">
                        <b-form-group label="Nombre">
                            <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.name"
                                            required
                                            placeholder="Ingresar un nombre">
                            </b-form-input>
                        </b-form-group>
                    </b-col>         

                    <b-col md="12">
                        <b-form-group label="Email">
                            <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.email"
                                            required
                                            placeholder="Ingresar un email">
                            </b-form-input>
                        </b-form-group>            
                    </b-col>

                    <b-col md="12">
                        <b-form-group label="Teléfono" description="Cod país [sin el signo] + nueve [9] + cod área [sin el 0] + nro cel [sin el 15] (total 13 dígitos)">
                            <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.phone"
                                            required
                                            placeholder="Ingresar un teléfono">
                            </b-form-input>
                        </b-form-group>            
                    </b-col>

                    <b-col md="12">
                        <b-form-group label="Puesto">
                            <b-form-input type="text"
                                            size="sm"
                                            v-model="crud.form.market_stall"
                                            required
                                            placeholder="Ingresar un puesto">
                            </b-form-input>
                        </b-form-group>            
                    </b-col>

                    <b-col md="12">
                        <b-form-group label="Observaciones">                            
                            <b-form-textarea                
                                v-model="crud.form.observations"
                                placeholder="Ingresar las observaciones..."
                                rows="3"
                                max-rows="6"
                            ></b-form-textarea>                            
                        </b-form-group>            
                    </b-col>                                        

                </b-row>

                <div slot="modal-footer">
                    <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
                    <b-button variant="dark" @click="save()">Guardar</b-button>          
                </div>
            </b-modal>            
        </b-col>    
    </b-row>
</template>
<script>
    import serviceAPI from './../services'
    import Error from '@/handler/error'
    import Session from '@/handler/session'
    import Profiles from '@/config/profiles'
    import Modules from '@/config/modules'
    import Helper from '@/handler/helper'    

    export default {
        props: [
            'customers',
            'customersID',
        ],
        data() {
            return {
                crud: {
                    form: {
                        id: 0,
                        customer_id: 0,
                        name: '',
                        phone: '',
                        email: '',
                        market_stall: '',
                        observations: '',
                    }
                },
                table : {
                    items: [],
                    fields: [                        
                        {key: 'name', label: 'Nombre', class: 'align-middle', width:"100px"},
                        {key: 'contacts', label: 'Email', class: 'align-middle', width:"240px"},                                                
                        {key: 'f_action', label:'', class: 'align-middle', width:"30px"},
                    ],             
                },
                modal: {
                    form: {
                        active: false,
                        title: ''
                    },
                }                 
                
            }
        },       
        watch: {       
            customersID: function (newQuestion, oldQuestion) {
                this.load()
            }
        },         
        mounted(){
            if(this.customersID) {
                this.load()
            }            
        },
        computed: {
          isSeller() {
            var seller = Helper.getSeller()
            if(seller) {
              return true
            } else {
              return false
            }
          },  
        },
        methods: {
            load() {
                let loader = this.$loading.show();

                var result = serviceAPI.obtenerContacts(this.customersID)
                    result.then((response) => {
                    var data = response.data
                    this.table.items = data
                                    
                    loader.hide()         
                })
                .catch(error => {
                    loader.hide()
                    this.$awn.alert(Error.showError(error))
                });   
            },
            add() {
                this.crud.form.id = 0
                this.crud.form.customer_id = this.customersID
                this.crud.form.name = ''
                this.crud.form.phone = ''
                this.crud.form.email = ''
                this.crud.form.market_stall = ''
                this.crud.form.observations = ''
                
                this.modal.form.title = "Nuevo Contacto"
                this.modal.form.active = true
            },
            edit(item) {
                this.crud.form.id = item.id
                this.crud.form.customer_id = item.customer_id
                this.crud.form.name = item.name
                this.crud.form.phone = item.phone
                this.crud.form.email = item.email
                this.crud.form.market_stall = item.market_stall
                this.crud.form.observations = item.observations

                this.modal.form.title = "Editar Contacto"
                this.modal.form.active = true
            },            
            save() {
                // valida que tenga el formato correcto para emails
                if(this.crud.form.email) {
                    var expreg = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
                    if (!expreg.test(this.crud.form.email)) {          
                        this.$awn.alert("El email no posee un formato válido")          
                        return false
                    }       
                }

                // valida que tenga el formato para whatsapp
                if(this.crud.form.phone) {
                    if(this.crud.form.phone.length != 13) {
                        this.$awn.alert("El nro de télefono no posee un formato válido")
                        return false
                    }
                }

                let loader = this.$loading.show();                
                
                if (this.crud.form.id) {
                    var result = serviceAPI.editarContacts(this.crud.form, this.customersID);
                } else {
                    var result = serviceAPI.agregarContacts(this.crud.form, this.customersID);
                }

                result.then((response) => {                    
                    this.modal.form.active = false
                    loader.hide()                                        
                    this.load()                                        
                    this.$awn.success("Datos guardados con éxito");
                })
                .catch(error => {
                    loader.hide()
                    this.modal.form.active = false
                    this.$awn.alert(Error.showError(error));
                })
            },
            remove(item) {
                this.crud.form.id = item.id
                this.crud.form.name = item.name        

                this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
                    title: 'Borrar Contacto',
                    size: 'lg',
                    buttonSize: 'lg',
                    okVariant: 'danger',
                    okTitle: 'SI',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    headerBgVariant: 'danger',
                    headerTextVariant: 'white',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        let loader = this.$loading.show();
                        var result = serviceAPI.eliminarContacts(this.crud.form.id, this.customersID);

                        result.then((response) => {      
                            this.modal.form.active = false        
                            loader.hide()                                                        
                            this.load()
                            this.$awn.success("Registro eliminado");
                        })
                        .catch(error => {
                            loader.hide()
                            this.modal.form.active = false
                            this.$awn.alert(Error.showError(error));
                        })
                    }
                })
                .catch(error => {
                    this.$awn.alert(Error.showError(error));
                })
            },            
        }
    }
</script>
<style>
    .crud-customers-contacts-table {
        overflow: auto;
        font-size: 12px;
        min-height: 80px;
    }
    .crud-customers-contacts-table td:first-child {
        line-height: 12px;
    }
    .crud-customers-contacts-table-market-stall {
        font-size: 10px;
    }
    .crud-customers-contacts-table-name {
        font-size: 15px;
        font-weight: bold;
    }    
    .crud-customers-contacts-table-observations {
        cursor: pointer;
    }
</style>