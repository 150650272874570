<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="12">
            <b-row>
              <b-col sm="4">
                <FindObject render="search"                        
                        type="erpPointsSales" 
                        display="full_identification"
                        @select-object="loadPointSales($event)" 
                        :valueID="filters.points_sales_id"/>
              </b-col>               
              <b-col md="3">
                <b-form-group label="Desde">    
                  <b-form-input type="date" size="sm" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Hasta">    
                  <b-form-input type="date" size="sm" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                </b-form-group>
              </b-col>  
              <b-col sm="2" class="current-account-customer-filter-align">
                <b-button variant="outline-dark" size="sm" @click="filterReport()">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>                                     
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
              <b-tab title="Por Factura" @click="setTabActive('invoice')">               
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"                    
                        :fields="tableInvoice.fields"                      
                        :items="formartedItemsInvoice"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected"
                        :busy="tableInvoice.isBusy"       
                        v-if="tableInvoice.items.length || tableInvoice.isBusy">

                  <template v-slot:row-details="row">
                    <b-card no-body>
                      <b-row class="p-3">
                        <b-col md="10">
                          <b-table class="mb-0 table-full-detail-sales"
                                  responsive
                                  head-variant="dark"
                                  :hover="true"
                                  :small="true"
                                  :fixed="true"
                                  :items="tableDetailsSales.imputation.items"
                                  :fields="tableDetailsSales.imputation.fields"
                                  v-if="tableDetailsSales.imputation.items.length">                       
                              
                            <template v-slot:table-colgroup="scope">
                              <col
                                v-for="field in scope.fields"                    
                                :key="field.key"
                                :style="{ width: field.width }"
                              >
                            </template>           

                            <template v-slot:cell(date)="data"> 
                              {{moment(data.item.date).format('DD/MM/YYYY')}}
                            </template>              

                            <template v-slot:cell(reference)="data"> 
                              {{data.item.reference}}                          
                            </template>

                            <template v-slot:cell(amount_total)="data"> 
                              {{Intl.NumberFormat('es-AR',{style:'currency',currency: itemSelectedSales.currency.code}).format(data.item.amount_total)}}           
                            </template>         

                          </b-table>
                          <b-alert v-else variant="info" show>No se encontraron comprobantes imputados</b-alert>              
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>

                  <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Cargando...</strong>
                    </div>
                  </template>

                  <template v-slot:cell(date)="data">
                    <div v-if="data.item.date">
                      {{ moment(data.item.date).format('DD MMMM YYYY') }}
                    </div>
                  </template>

                  <template v-slot:cell(date_expiration)="data">
                    <div v-if="data.item.date_expiration">
                      {{ moment(data.item.date_expiration).format('DD MMMM YYYY') }}
                    </div>
                  </template>

                  <template v-slot:cell(description)="data">
                    {{data.item.description}}
                  </template>

                  <template v-slot:cell(debit)="data">
                      <div v-if="data.item.debit" style="color:green;">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.debit)}}
                      </div>                  
                  </template>

                  <template v-slot:cell(credit)="data">
                      <div v-if="data.item.credit" style="color:red;">
                        ({{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.credit)}})
                      </div>
                  </template>

                  <template v-slot:cell(balance)="data">
                    <div v-if="data.item.debit" style="color:green;">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.balance)}}
                    </div>
                    <div v-if="data.item.credit" style="color:red;">
                      ({{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.balance)}})
                    </div>
                  </template>

                  <template v-slot:cell(accumulated)="data">
                    <b v-if="data.item.accumulated">                      
                      <b v-if="data.index==0">
                        <span :class="{'current-accounts-accumulate-positive-total':parseFloat(data.item.accumulated)>=0, 'current-accounts-accumulate-negative-total':parseFloat(data.item.accumulated)<0}">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.accumulated)}}
                        </span>                      
                      </b>
                      <b v-else>
                        <span :class="{'current-accounts-accumulate-positive':parseFloat(data.item.accumulated)>=0, 'current-accounts-accumulate-negative':parseFloat(data.item.accumulated)<0}">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.accumulated)}}
                        </span>
                      </b>                      
                    </b>     
                  </template>

                  <template v-slot:cell(f_action)="data">
                    <b-dropdown right size="sm" variant="outline-dark" class="pull-right" v-if="data.item.print">
                      <b-dropdown-header>Acciones</b-dropdown-header>
                      <b-dropdown-item @click="sendPrinter(data.item)">
                        <b-icon icon="printer"></b-icon> Imprimir
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>                  
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>              
              </b-tab>            
              <b-tab title="Pendiente" @click="setTabActive('pending')">             
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"                  
                        :fields="tablePending.fields"                    
                        :items="formartedItemsPending"
                        selectable
                        select-mode="single"
                        :busy="tablePending.isBusy"     
                        v-if="tablePending.items.length || tablePending.isBusy">

                  <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Cargando...</strong>
                    </div>
                  </template>

                  <template v-slot:cell(date)="data">
                    <div v-if="data.item.date">
                      {{ moment(data.item.date).format('DD MMMM YYYY') }}
                    </div>
                  </template>

                  <template v-slot:cell(date_expiration)="data">
                    <div v-if="data.item.date_expiration">
                      {{ moment(data.item.date_expiration).format('DD MMMM YYYY') }}
                    </div>
                  </template>

                  <template v-slot:cell(description)="data">
                    {{data.item.description}}
                  </template>

                  <template v-slot:cell(debit)="data">
                      <div v-if="data.item.debit" style="color:green;">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.debit)}}
                      </div>                  
                  </template>

                  <template v-slot:cell(credit)="data">
                      <div v-if="data.item.credit" style="color:red;">
                        ({{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.credit)}})
                      </div>
                  </template>

                  <template v-slot:cell(accumulated)="data">
                    <b v-if="data.item.accumulated">                      
                      <b v-if="data.index==0">
                        <span :class="{'current-accounts-accumulate-positive-total':parseFloat(data.item.accumulated)>=0, 'current-accounts-accumulate-negative-total':parseFloat(data.item.accumulated)<0}">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.accumulated)}}
                        </span>                      
                      </b>
                      <b v-else>
                        <span :class="{'current-accounts-accumulate-positive':parseFloat(data.item.accumulated)>=0, 'current-accounts-accumulate-negative':parseFloat(data.item.accumulated)<0}">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.accumulated)}}
                        </span>
                      </b>                      
                    </b>     
                  </template>

                  <template v-slot:cell(f_action)="data">
                    <b-dropdown right size="sm" variant="outline-dark" class="pull-right" v-if="data.item.print">
                      <b-dropdown-header>Acciones</b-dropdown-header>
                      <b-dropdown-item @click="sendPrinter(data.item)">
                        <b-icon icon="printer"></b-icon> Imprimir
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>                  
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>              
              </b-tab>
              <b-tab title="Histórica" @click="setTabActive('history')" > 
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"                    
                        :fields="tableHistory.fields"
                        :items="formartedItemsHistory"
                        selectable
                        select-mode="single"
                        :busy="tableHistory.isBusy"       
                        v-if="tableHistory.items.length || tableHistory.isBusy">

                  <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Cargando...</strong>
                    </div>
                  </template>

                  <template v-slot:cell(date)="data">
                    <div v-if="data.item.date">
                      {{ moment(data.item.date).format('DD MMMM YYYY') }}
                    </div>
                  </template>

                  <template v-slot:cell(date_expiration)="data">
                    <div v-if="data.item.date_expiration">
                      {{ moment(data.item.date_expiration).format('DD MMMM YYYY') }}
                    </div>
                  </template>

                  <template v-slot:cell(description)="data">
                    {{data.item.description}}
                  </template>

                  <template v-slot:cell(debit)="data">
                      <div v-if="data.item.debit" style="color:green;">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.debit)}}
                      </div>                  
                  </template>

                  <template v-slot:cell(credit)="data">
                      <div v-if="data.item.credit" style="color:red;">
                        ({{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.credit)}})
                      </div>
                  </template>

                  <template v-slot:cell(accumulated)="data">
                      <b v-if="data.item.accumulated">                      
                        <b v-if="data.index==0">
                          <span :class="{'current-accounts-accumulate-positive-total':parseFloat(data.item.accumulated)>=0, 'current-accounts-accumulate-negative-total':parseFloat(data.item.accumulated)<0}">
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.accumulated)}}
                          </span>                      
                        </b>
                        <b v-else>
                          <span :class="{'current-accounts-accumulate-positive':parseFloat(data.item.accumulated)>=0, 'current-accounts-accumulate-negative':parseFloat(data.item.accumulated)<0}">
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.accumulated)}}
                          </span>
                        </b>                      
                      </b>              
                  </template>

                  <template v-slot:cell(f_action)="data">
                    <b-dropdown right size="sm" variant="outline-dark" class="pull-right" v-if="data.item.print">
                      <b-dropdown-header>Acciones</b-dropdown-header>
                      <b-dropdown-item @click="sendPrinter(data.item)">
                        <b-icon icon="printer"></b-icon> Imprimir
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './../services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import Vue from 'vue'
  import FindObject from '@/components/inc/find/findObject' 
  import Printer from '@/components/inc/printer/printer'

  export default {
    components: {
      FindObject,
      Printer,       
    },         
    props: [
        'customers',
        'customersID',
    ],
    data: () => {
      return {
        tableHistory : {
          items: [],
          filters: null,
          fields: [            
            {key: 'date', label: 'Fecha', class: "align-middle"},
            {key: 'description', label: 'Comprobante', class: "align-middle"},
            {key: 'date_expiration', label: 'Vencimiento', class: "align-middle"},            
            {key: 'debit', label: 'Debe', class: "align-middle text-right"},
            {key: 'credit', label: 'Haber', class: "align-middle text-right"},         
            {key: 'accumulated', label: 'Acumulado', class: "align-middle text-right"},
            {key: 'f_action', label:'', class: "align-middle"},
          ],          
          isBusy: false,
        },
        tablePending : {
          items: [],
          filters: null,
          fields: [            
            {key: 'date', label: 'Fecha', class: "align-middle"},
            {key: 'description', label: 'Comprobante', class: "align-middle"},
            {key: 'date_expiration', label: 'Vencimiento', class: "align-middle"},            
            {key: 'debit', label: 'Debe', class: "align-middle text-right"},
            {key: 'credit', label: 'Haber', class: "align-middle text-right"},         
            {key: 'accumulated', label: 'Acumulado', class: "align-middle text-right"},
            {key: 'f_action', label:'', class: "align-middle"},
          ],          
          isBusy: false,
        },    
        tableInvoice : {
          items: [],
          filters: null,
          fields: [            
            {key: 'date', label: 'Fecha', class: "align-middle"},
            {key: 'description', label: 'Comprobante', class: "align-middle"},
            {key: 'date_expiration', label: 'Vencimiento', class: "align-middle"},            
            {key: 'debit', label: 'Debe', class: "align-middle text-right"},
            {key: 'credit', label: 'Haber', class: "align-middle text-right"},         
            {key: 'balance', label: 'Saldo', class: "align-middle text-right"},         
            {key: 'accumulated', label: 'Acumulado', class: "align-middle text-right"},
            {key: 'f_action', label:'', class: "align-middle"},
          ],          
          isBusy: false,
        },  
        tableDetailsSales: {          
          imputation : {
            items: [],
            fields: [
              {key: 'date', label: 'Fecha', class:"align-middle", width:"20%"},                
              {key: 'reference', label: 'Referencia', class:"align-middle", width:"50%"},
              {key: 'amount_total', label: 'Total', class:"align-middle text-right", width:"30%"},                   
            ]
          },         
        },         
        crud: {
          print: {
            reference: '',
            id: 0,
            key: 0,
          }     
        },
        filters: {
          date_start: '',
          date_end: '',
          customers_id: 0,
          customers: null,
          points_sales: null,
          points_sales_id: 0,             
        },    
        tabSelect: 'invoice', 
        itemSelectedSales: null,      
        arr: {
          invoice: [],
        }                 
      }
    }, 
    mounted() {
      this.filterLoad()
      this.filterReport()
    },
    computed: {
      // CONFIGURACION      
      getCurrency() {
        return 'ARS'
      },  

      formartedItemsHistory () {
        if (!this.tableHistory.items) return []
        return this.tableHistory.items.map(item => {
          if(!item.print){
            item._rowVariant  = 'secondary'
          }
          
          return item
        })
      },
      formartedItemsPending () {
        if (!this.tablePending.items) return []
        return this.tablePending.items.map(item => {
          if(!item.print){
            item._rowVariant  = 'secondary'
          }
          
          return item
        })
      },
      formartedItemsInvoice () {
        if (!this.tableInvoice.items) return []
        return this.tableInvoice.items.map(item => {
          if(!item.print){
            item._rowVariant  = 'secondary'
          }
          
          return item
        })
      }    
    },
    methods: {
      onRowSelected(item) {
        this.arr.invoice.forEach((element, index) => {  
          if(item.length) {  
            if(item[0].id>0 && item[0].typeOperation=='sales') {
              if(element.id == item[0].id) {
                if(this.tableInvoice.items[index]._showDetails) {
                  this.tableInvoice.items[index]._showDetails = false
                } else {                
                  this.tableInvoice.rowSelected = index
                  this.filterReport()                
                }                       
              }
            }                        
          } else {
            this.tableInvoice.items[index]._showDetails = false
          }         
        })  
      },           
      filterLoad() { 
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD')         
      },
      loadPointSales (object) {
        if(object){
          this.filters.points_sales = object
          this.filters.points_sales_id = object.id      

          this.filterReport()                          
        } else {
          this.filters.points_sales = null
          this.filters.points_sales_id = 0          

          this.filterReport()                          
        }
      },         
      filterReport(){
        this.filters.customers_id = this.customersID
        
        if(!this.filters.customers_id) {
          this.$awn.alert("Debe seleccionar un cliente");
          return false;
        }
        
        this.tablePending.isBusy = true
        this.tableHistory.isBusy = true
        this.tableInvoice.isBusy = true
                 
        if(this.tabSelect=='history') {
          var resultHistory = serviceAPI.filtrarCurrentsAccounts(this.filters)        
          resultHistory.then((response) => {                                
            this.tablePending.items = []
            this.tableInvoice.items = []
            this.tableHistory.items = response.data            
            
            this.tablePending.isBusy = false
            this.tableHistory.isBusy = false
            this.tableInvoice.isBusy = false
          })          
        }
        if(this.tabSelect=='pending') {          
          var resultPending = serviceAPI.filtrarCurrentsAccountsPending(this.filters)        
          resultPending.then((response) => {                                
            this.tableHistory.items = []
            this.tableInvoice.items = []
            this.tablePending.items = response.data                        

            this.tablePending.isBusy = false
            this.tableHistory.isBusy = false
            this.tableInvoice.isBusy = false
          })                    
        }
        if(this.tabSelect=='invoice') {          
          var resultInvoice = serviceAPI.filtrarCurrentsAccountsInvoice(this.filters)        
          resultInvoice.then((response) => {                                
            this.tableHistory.items = []
            this.tablePending.items = []
            this.tableInvoice.items = response.data
            
            this.arr.invoice  = response.data

            this.tablePending.isBusy = false
            this.tableHistory.isBusy = false
            this.tableInvoice.isBusy = false

            if(this.tableInvoice.rowSelected!=null) {              
              this.tableInvoice.items[this.tableInvoice.rowSelected]._showDetails = true      
              
              var result = serviceAPI.filtrarComprobanteById({
                id: this.tableInvoice.items[this.tableInvoice.rowSelected].regID,
              }) 
              result.then((response) => {    
                var data = response.data
                this.itemSelectedSales = data

                // IMPUTACIONES
                var arrImputations = []        
                if(data.receipts) {
                  data.receipts.forEach(element => {
                    arrImputations.push({
                      date: element.receipts.date,
                      reference: element.receipts.type_voucher.name + ' ' + element.receipts.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.receipts.number.toString().padStart(8,'0'),
                      amount_total: element.amount_total
                    })
                  });
                }
              
                if(data.sales_related) {          
                  data.sales_related.forEach(element => {              
                    arrImputations.push({
                      date: element.sale_related.date,
                      reference: element.sale_related.type_voucher.name + ' ' + element.sale_related.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.sale_related.number.toString().padStart(8,'0'),
                      amount_total: element.amount_total
                    })
                  });
                }   
              
                if(data.sales_related_n_c) {          
                  data.sales_related_n_c.forEach(element => {              
                    arrImputations.push({
                      date: element.sale.date,
                      reference: element.sale.type_voucher.name + ' ' + element.sale.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.sale.number.toString().padStart(8,'0'),
                      amount_total: element.amount_total
                    })
                  });
                }   

                this.tableDetailsSales.imputation.items = arrImputations                    
              })
            }            
          })                    
        }        
      },
      sendPrinter(item) {
        this.crud.print.key=Math.random()
        this.crud.print.id=item.regID
        this.crud.print.reference=item.reportDefault        
      },
      setTabActive(type) {        
        this.tabSelect = type
        if(this.filters.customers_id) {
          this.filterReport()                
        }
      },
    }    
  }
</script>
<style>
  .current-account-customer-filter-align {
    margin-top: 30px;
  }
</style>
<style scoped>
  .crud-sales-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }
  .crud-sales-value-table-custom {
    padding-left: 5px;
  }  
  .crud-receipts-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }
  .crud-receipts-value-table-custom {
    padding-left: 5px;
  }    
  .current-accounts-accumulate-negative-total {  
    font-size: 16px;
    color: white;
    background: red;
    padding: 3px;    
  }
  .current-accounts-accumulate-positive-total {
    font-size: 16px;
    color: white;
    background: green;
    padding: 3px;        
  }  
  .current-accounts-accumulate-negative {      
    color: red;
  }
  .current-accounts-accumulate-positive {    
    color: green;
  }    
</style>