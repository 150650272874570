<template>   
  <div> 
    <b-row>
        <b-col lg="6">            
            <b-card header="General"
                    header-text-variant="white"
                    header-tag="header"
                    header-bg-variant="dark">
                <b-row>
                    <b-col lg="6">
                        <b-form-group label="Razón Social">
                            <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.business_name"
                                        :disabled="isSeller">
                            </b-form-input>           
                        </b-form-group>
                    </b-col>
                    
                    <b-col lg="6">
                        <FindObject render="search"
                                    type="erpFiscalConditions" 
                                    @select-object="loadFiscalConditions($event)" 
                                    :valueID="crud.form.fiscal_conditions_id"                                        
                                    :key="componentKey"
                                    :disabled="isSeller"/>   
                    </b-col>

                    <b-col lg="6">
                        <FindObject render="search"
                                    type="erpTypeDocuments" 
                                    @select-object="loadTypeDocuments($event)" 
                                    :valueID="crud.form.types_documents_id"
                                    :key="componentKey"
                                    :disabled="isSeller"/>                       
                    </b-col>
                            
                    <b-col lg="6">
                        <b-form-group label="Número Documento">
                            <b-form-input type="text"
                                        size="md"
                                        v-model="crud.form.documents_number"
                                        :disabled="isSeller">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
        <b-col lg="6">
            <b-card header="Ubicación"
                    header-text-variant="white"
                    header-tag="header"
                    header-bg-variant="dark">            
                <b-row>
                    <b-col lg="8">
                        <b-form-group label="Dirección">
                            <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.address"
                                        :disabled="isSeller">
                            </b-form-input>           
                        </b-form-group>
                    </b-col>

                    <b-col lg="4">
                        <b-form-group label="Código Postal">
                            <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.postal_code"
                                        :disabled="isSeller">
                            </b-form-input>           
                        </b-form-group>
                    </b-col>

                    <b-col lg="4">
                        <b-form-group label="Ciudad">
                            <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.city"
                                        :disabled="isSeller">
                            </b-form-input>           
                        </b-form-group>
                    </b-col>

                    <b-col lg="4">
                        <b-form-group label="Provincia">
                            <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.state"
                                        :disabled="isSeller">
                            </b-form-input>           
                        </b-form-group>
                    </b-col>

                    <b-col lg="4">
                        <b-form-group label="País">
                            <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.form.country"
                                        :disabled="isSeller">
                            </b-form-input>           
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    <b-row>
        <b-col lg="6">
            <b-card header="Por Defecto"     
                    header-text-variant="white"
                    header-tag="header"
                    header-bg-variant="dark">
                <b-row>
                    <b-col lg="6">
                        <FindObject render="search"
                                    type="erpPointsSales" 
                                    display="full_identification"
                                    @select-object="loadPointsSales($event)" 
                                    :valueID="crud.form.points_sales_id"
                                    description="Punto de venta por defecto"
                                    :key="componentKey"
                                    :disabled="isSeller"/>              
                    </b-col>
                
                    <b-col lg="6">
                        <FindObject render="search"
                                    type="erpMethodsPayment" 
                                    @select-object="loadMethodsPayment($event)" 
                                    :valueID="crud.form.methods_payment_id"
                                    description="Método de pago por defecto"
                                    :key="componentKey"
                                    :disabled="isSeller"/>                                      
                    </b-col>

                    <b-col lg="6">
                        <FindObject render="search"
                                    type="suppliers" 
                                    @select-object="loadShipping($event)" 
                                    :where="conditionSuppliersShippingActive"
                                    :valueID="crud.form.shipping_id"
                                    tag="Transporte"
                                    description="Transporte por defecto"
                                    :key="componentKey"
                                    :disabled="isSeller"/>                                      
                    </b-col>

                    <b-col lg="6">
                        <b-form-group label="Límite de Crédito">
                            <b-form-input type="number"
                                        size="sm"
                                        v-model="crud.form.credit_limit"
                                        :disabled="isSeller">
                            </b-form-input>           
                        </b-form-group>
                    </b-col>

                    <b-col lg="12" v-if="parameters.haveAcopio">
                        <FindObject render="search"
                                    type="erpMethodsAcopio" 
                                    @select-object="loadMethodsAcopio($event)" 
                                    :valueID="crud.form.methods_acopio_id"
                                    description="Método de acopio por defecto"
                                    :key="componentKey"
                                    :disabled="isSeller"/>
                    </b-col>

                </b-row>
            </b-card>
        </b-col>
        <b-col lg="6">
            <b-card header="Observaciones"
                    header-text-variant="white"
                    header-tag="header"
                    header-bg-variant="dark">
                <b-row>
                    <b-col lg="12">
                        <b-form-group label="Observaciones">
                            <b-form-textarea id="textarea"
                                            size="sm"
                                            rows="11"
                                            max-rows="6"
                                            v-model="crud.form.observations"
                                            :disabled="isSeller">
                            </b-form-textarea>
                        </b-form-group>
                    </b-col>                    
                </b-row>
            </b-card>
        </b-col>
        <b-col lg="12">            
            <b-button variant="dark" size="sm" class="pull-right" @click="save()" v-if="!isSeller">Guardar</b-button>
        </b-col>
    </b-row>
  </div>
</template>
<script>
    import serviceAPI from './../services'
    import Error from '@/handler/error'
    import Session from '@/handler/session'
    import Profiles from '@/config/profiles'
    import Modules from '@/config/modules'
    import Helper from '@/handler/helper'    
    import FindObject from '@/components/inc/find/findObject'  
    import Param from '@/config/parameters'  

    export default {
      components: {
        FindObject,                 
      },
      props: [
        'customers',
        'customersID',
      ],
      data() {
        return {
          parameters: {
            haveAcopio: Helper.hasParametersAccess(Param.P49),          
          },            
          crud: {
            form: {
              id: 0,
              customers_id: 0,
              business_name: '',
              types_documents: null,
              types_documents_id: 0,
              documents_number: '',
              fiscal_conditions: null,
              fiscal_conditions_id: 0,
              address: '',
              city: '',
              postal_code: '',
              state: '',
              country: '',
              credit_limit: 0.00,
              methods_payment: null,
              methods_payment_id: 0,
              observations: '',
              points_sales: null,
              points_sales_id: 0,
              shipping: null,
              shipping_id: 0,
              methods_acopio: null,
              methods_acopio_id: 0,                        
            }
          },                 
          componentKey: 0,
        }
      },        
      watch: {       
        customers: function (newQuestion, oldQuestion) {
          this.load()
        }
      },
      computed: {
        isSeller() {
          var seller = Helper.getSeller()
          if(seller) {
            return true
          } else {
            return false
          }
        },  

        // CONDITIONS SELECT
        conditionSuppliersShippingActive(){
          var condition = []
          condition.push({field: 'active', condition: true})
          condition.push({field: 'shipping', condition: true})
          return condition;
        },             
      },        
      methods: {
        load() {                
          if(this.customers.data_fiscal){
            this.crud.form.id = this.customers.data_fiscal.id,
            this.crud.form.customers_id = this.customers.data_fiscal.customers_id,
            this.crud.form.business_name = this.customers.data_fiscal.business_name,                    
            this.crud.form.documents_number = this.customers.data_fiscal.documents_number,                    
            this.crud.form.address = this.customers.data_fiscal.address,
            this.crud.form.city = this.customers.data_fiscal.city,
            this.crud.form.postal_code = this.customers.data_fiscal.postal_code,
            this.crud.form.state = this.customers.data_fiscal.state,
            this.crud.form.country = this.customers.data_fiscal.country,
            this.crud.form.credit_limit = this.customers.data_fiscal.credit_limit,                    
            this.crud.form.observations = this.customers.data_fiscal.observations   
            
            this.crud.form.types_documents = this.customers.data_fiscal.types_documents,
            this.crud.form.types_documents_id = this.customers.data_fiscal.types_documents_id,
            
            this.crud.form.fiscal_conditions = this.customers.data_fiscal.fiscal_conditions,
            this.crud.form.fiscal_conditions_id = this.customers.data_fiscal.fiscal_conditions_id,

            this.crud.form.methods_payment = this.customers.data_fiscal.methods_payment,
            this.crud.form.methods_payment_id = this.customers.data_fiscal.methods_payment_id,

            this.crud.form.points_sales_id = this.customers.data_fiscal.points_sales_id
            this.crud.form.points_sales = this.customers.data_fiscal.points_sales     

            this.crud.form.shipping_id = this.customers.data_fiscal.shipping_id
            this.crud.form.shipping = this.customers.data_fiscal.shipping                 
            
            this.crud.form.methods_acopio = this.customers.data_fiscal.methods_acopio,
            this.crud.form.methods_acopio_id = this.customers.data_fiscal.methods_acopio_id,

            this.forceRerender()        
          } else {
            this.crud.form.customers_id = this.customersID                    
          }                
        },

        save() {
          let loader = this.$loading.show();                
          
          if (this.crud.form.id) {
            var result = serviceAPI.editarSalesCustomers(this.crud.form);
          } else {
            var result = serviceAPI.agregarSalesCustomers(this.crud.form);
          }

          result.then((response) => {                    
            loader.hide()                    
            this.$awn.success("Datos guardados con éxito");
          })
          .catch(error => {
            loader.hide()
            this.$awn.alert(Error.showError(error));
          })
        },

        loadPointsSales (object) {                    
          if(object){
            this.crud.form.points_sales = object
            this.crud.form.points_sales_id = object.id             
          } else {
            this.crud.form.points_sales = null
            this.crud.form.points_sales_id = 0
          }
        },  
        loadMethodsPayment (object) {
          if(object){
            this.crud.form.methods_payment = object
            this.crud.form.methods_payment_id = object.id             
          } else {
            this.crud.form.methods_payment = null
            this.crud.form.methods_payment_id = 0
          }
        },
        loadFiscalConditions (object) {
          if(object){
            this.crud.form.fiscal_conditions = object
            this.crud.form.fiscal_conditions_id = object.id             
          } else {
            this.crud.form.fiscal_conditions = null
            this.crud.form.fiscal_conditions_id = 0
          }
        },
        loadTypeDocuments (object) {
          if(object){
            this.crud.form.types_documents = object
            this.crud.form.types_documents_id = object.id             
          } else {
            this.crud.form.types_documents = null
            this.crud.form.types_documents_id = 0
          }
        }, 
        loadShipping (object) {
          if(object){
            this.crud.form.shipping = object
            this.crud.form.shipping_id = object.id             
          } else {
            this.crud.form.shipping = null
            this.crud.form.shipping_id = 0
          }
        },      
        loadMethodsAcopio (object) {
          if(object){
            this.crud.form.methods_acopio = object
            this.crud.form.methods_acopio_id = object.id             
          } else {
            this.crud.form.methods_acopio = null
            this.crud.form.methods_acopio_id = 0
          }
        },                               
        forceRerender() {
          this.componentKey += 1;  
        },                        
      }
    }
</script>
<style scoped>
  .card-header {
    padding: 1px;   
    padding-left: 5px;   
    font-weight: bold;     
  }
</style>