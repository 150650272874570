<template>
  <b-row>
    <b-col lg="12">    
      <FindObject render="search"
                  tag="Cuenta Contable de Activo"
                  type="accountingAccountsImputation" 
                  @select-object="loadAccountingAccounts($event)" 
                  :valueID="crud.form.accounting_accounts_id"
                  :where="conditionAccountingAccountsFilters"
                  :key="componentKey"
                  :disabled="isSeller"/>
    </b-col>
    <b-col lg="12">
      <FindObject render="search"
                  tag="Cuenta Contable de Ingreso"
                  type="accountingAccountsImputation" 
                  @select-object="loadAccountingAccountsSecondary($event)" 
                  :valueID="crud.form.accounting_accounts_secondary_id"
                  :where="conditionAccountingAccountsFiltersSecondary"
                  :key="componentKey"
                  :disabled="isSeller"/>
    </b-col>
    <b-col lg="12">            
      <b-button variant="dark" class="pull-right" size="sm" @click="save()" v-if="!isSeller">Guardar</b-button>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './../services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'    
  import FindObject from '@/components/inc/find/findObject'  

  export default {
    components: {
      FindObject,        
    },        
    props: [
      'customers',
      'customersID',
    ],
    data() {
      return {
        crud: {
          form: {
            id: 0,
            customers_id: 0,
            accounting_accounts: null,
            accounting_accounts_id: 0,
            accounting_accounts_secondary: null,
            accounting_accounts_secondary_id: 0
          }
        },
        componentKey: 0,
      }
    }, 
    computed: {
      // CONDITIONS SELECT
      conditionAccountingAccountsFilters(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Activo'}
        ];
      },  
      conditionAccountingAccountsFiltersSecondary(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Ingresos'}
        ];
      },  
      isSeller() {
        var seller = Helper.getSeller()
        if(seller) {
          return true
        } else {
          return false
        }
      },      
    },
    watch: {        
      customers: function (newQuestion, oldQuestion) {
        this.load()
      }
    },        
    methods: {
      load() {        
        if(this.customers.accounting_account){
          
          this.crud.form.id = this.customers.accounting_account.id,
          this.crud.form.customers_id = this.customers.accounting_account.customers_id,
                                              
          this.crud.form.accounting_accounts = this.customers.accounting_account.accounting_account
          this.crud.form.accounting_accounts_id = this.customers.accounting_account.accounting_accounts_id     

          this.crud.form.accounting_accounts_secondary = this.customers.accounting_account.accounting_account_secondary
          this.crud.form.accounting_accounts_secondary_id = this.customers.accounting_account.accounting_accounts_secondary_id     
          
          this.forceRerender()        
        } else {
          this.crud.form.customers_id = this.customersID                    
        }
      },
      
      
      save() {
        if(!this.crud.form.accounting_accounts_id && !this.crud.form.accounting_accounts_secondary_id && !this.crud.form.id){
          return false
        }

        var typeOperation = ''
        let loader = this.$loading.show();                

        if(!this.crud.form.accounting_accounts_id && !this.crud.form.accounting_accounts_secondary_id){
          typeOperation = 'delete'
          var result = serviceAPI.eliminarCuentaContable(this.crud.form.id);
        } else {        
          if(this.crud.form.id) {
            typeOperation = 'edit'
            var result = serviceAPI.editarCuentaContable(this.crud.form);
          } else {
            typeOperation = 'save'
            var result = serviceAPI.agregarCuentaContable(this.crud.form);
          }                    
        }                

        result.then((response) => {                    
          loader.hide()
          this.$awn.success("Datos guardados con éxito");

          if(typeOperation != 'delete') {
            this.crud.form.id = response.data.id
          } else {
            this.crud.form.id = 0
          }
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      loadAccountingAccounts (object) {
        if(object){
          this.crud.form.accounting_accounts = object
          this.crud.form.accounting_accounts_id = object.id             
        } else {
          this.crud.form.accounting_accounts = null
          this.crud.form.accounting_accounts_id = 0
        }
      },
      loadAccountingAccountsSecondary (object) {
        if(object){
          this.crud.form.accounting_accounts_secondary = object
          this.crud.form.accounting_accounts_secondary_id = object.id             
        } else {
          this.crud.form.accounting_accounts_secondary = null
          this.crud.form.accounting_accounts_secondary_id = 0
        }
      },    
      forceRerender() {
        this.componentKey += 1;  
      },
    }
  }
</script>