<template>
    <div>
        <b-row>
            <b-col lg="12">
                <b-card-group deck>
                    <b-card border-variant="dark"
                            header-bg-variant="dark"
                            header-text-variant="white"
                            align="center"
                            class="view-customer-access-reports-widget-card"
                            v-if="accessExtras.INFORME_VISITAS.status" >
                        <div slot="header" class="font-weight-bold">
                            <strong> Última Visita </strong>                        
                        </div>                    
                        <b-card-text>
                            <div v-html="setInfoToReports('visits')"> </div>              
                        </b-card-text>
                    </b-card>

                    <b-card border-variant="dark"
                            header-bg-variant="dark"
                            header-text-variant="white"
                            align="center"
                            class="view-customer-access-reports-widget-card"
                            v-if="accessExtras.INFORME_COBRANZAS.status">
                        <div slot="header" class="font-weight-bold">
                            <strong> Última Cobranza </strong>                        
                        </div>                       
                        <b-card-text>
                            <div v-html="setInfoToReports('payments')" />               
                        </b-card-text>
                    </b-card>

                    <b-card border-variant="dark"                      
                            header-bg-variant="dark"
                            header-text-variant="white"
                            align="center"
                            class="view-customer-access-reports-widget-card"
                            v-if="accessExtras.INFORME_NOVEDADES.status">
                        <div slot="header" class="font-weight-bold">
                            <strong> Última Novedad </strong>                        
                        </div>                      
                        <b-card-text>
                            <div v-html="setInfoToReports('news')" />                     
                        </b-card-text>
                    </b-card>    
                </b-card-group>
            </b-col>
            
            <b-col lg="12" class="mt-3">
                <b-card-group deck>
                    <b-card border-variant="dark"                      
                            header-bg-variant="dark"
                            header-text-variant="white"
                            align="center"
                            class="view-customer-access-reports-widget-card"
                            v-if="accessExtras.INFORME_SERVICIOS.status">
                        <div slot="header" class="font-weight-bold">
                            <strong> Último Servicio </strong>                        
                        </div>                      
                        <b-card-text>
                            <div v-html="setInfoToReports('services')" />                     
                        </b-card-text>
                    </b-card>      

                    <b-card border-variant="dark"                      
                            header-bg-variant="dark"
                            header-text-variant="white"
                            align="center"
                            class="view-customer-access-reports-widget-card"
                            v-if="accessExtras.INFORME_MARKETING.status">
                        <div slot="header" class="font-weight-bold">
                            <strong> Último Marketing </strong>                        
                        </div>                      
                        <b-card-text>
                            <div v-html="setInfoToReports('marketing')" />                     
                        </b-card-text>
                    </b-card>    

                    <b-card border-variant="dark"                      
                            header-bg-variant="dark"
                            header-text-variant="white"
                            align="center"
                            class="view-customer-access-reports-widget-card"
                            v-if="accessExtras.INFORME_AUDITORIA.status">
                        <div slot="header" class="font-weight-bold">
                            <strong> Última Auditoria </strong>                        
                        </div>                      
                        <b-card-text>
                            <div v-html="setInfoToReports('audit')" />                     
                        </b-card-text>
                    </b-card>    
                </b-card-group>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col lg="9">
                <b-card-group>
                    <b-card border-variant="dark"
                            header-bg-variant="dark"
                            header-text-variant="white"
                            align="center"
                            class="view-customer-access-reports-widget-card">
                        
                        <div slot="header" class="font-weight-bold">
                            <strong> Historial de Informes </strong>                        
                        </div> 
                        <b-card-text class="view-customer-access-reports-widget-scroll">
                        
                            <b-list-group v-if="arrReports.length > 0">
                                <b-list-group-item class="flex-column align-items-start view-customer-access-reports-widget-link-reports" 
                                                    v-for="value in arrReports" 
                                                    v-bind:key="value.created_at" 
                                                    @click="goDetailReport(value, value.type)">
                                    <b-row>                  
                                        <b-col>                          
                                            <div v-if="value.type=='COBRANZAS'"
                                                class="view-customer-access-reports-widget-info-extra">
                                                NRO RECIBO: <b>{{value.id}}</b>
                                            </div>
                                            <div class="d-flex w-100 justify-content-between">                    
                                                <h5 class="mb-1">Informe de <u>{{value.type}}</u></h5>    
                                                <small class="view-customer-access-reports-widget-date">                                                
                                                    {{moment(value.date).format('DD/MM/YYYY')}}
                                                </small>                            
                                            </div>                                          
                                            <div class="view-customer-access-reports-widget-info-extra">  
                                                <div v-if="value.observations">
                                                    {{value.observations}}
                                                </div> 
                                                <span v-if="value.staff_id">
                                                    <font color="red"><b>Usuario:</b> {{ value.responsable }}</font>
                                                </span>
                                                <br>                                               
                                                <span v-if="value.sellers_id>0">
                                                    <font color="blue"><b>Vendedor:</b> {{ value.responsable }}</font>
                                                </span>
                                                <div v-if="value.type != 'AUDITORIA'">
                                                    <b-badge v-if="!value.read" variant="dark" pill class="pull-right" >SIN ABRIR</b-badge>
                                                    <b-badge v-else variant="secondary" pill class="pull-right" >LEIDO</b-badge>
                                                </div>
                                            </div>                                      
                                        </b-col>
                                    </b-row>                  
                                </b-list-group-item>                    
                            </b-list-group>
                                        
                            <b-alert v-else show variant="warning">
                                No se encontraron registros
                            </b-alert>  

                        </b-card-text>
                    </b-card>
                </b-card-group>
            </b-col>

            <b-col lg="3">
                <b-list-group class="mb-1 view-customer-access-reports" v-if="accessExtras.INFORME_VISITAS.status">
                    <b-list-group-item href="#" class="flex-column align-items-start" @click="goReportVisit()">
                        <div class="d-flex w-100 justify-content-between">
                            <h5>Visitas</h5>
                            <small>
                                <b-icon icon="chevron-double-right" class="h5"></b-icon>
                            </small>
                        </div>
                    </b-list-group-item>
                </b-list-group>

                <b-list-group class="mb-1 view-customer-access-reports" v-if="accessExtras.INFORME_COBRANZAS.status">
                    <b-list-group-item href="#" class="flex-column align-items-start" @click="goReportPayment()">
                        <div class="d-flex w-100 justify-content-between">
                            <h5>Cobranzas</h5>
                            <small>
                                <b-icon icon="chevron-double-right" class="h5"></b-icon>
                            </small>
                        </div>
                    </b-list-group-item>
                </b-list-group>

                <b-list-group class="mb-1 view-customer-access-reports" v-if="accessExtras.INFORME_NOVEDADES.status">
                    <b-list-group-item href="#" class="flex-column align-items-start" @click="goReportNews()">
                        <div class="d-flex w-100 justify-content-between">
                            <h5>Novedades</h5>
                            <small>
                                <b-icon icon="chevron-double-right" class="h5"></b-icon>
                            </small>
                        </div>
                    </b-list-group-item>
                </b-list-group>

                <b-list-group class="mb-1 view-customer-access-reports" v-if="accessExtras.INFORME_SERVICIOS.status" v-show="!isSeller">
                    <b-list-group-item href="#" class="flex-column align-items-start" @click="goReportServices()">
                        <div class="d-flex w-100 justify-content-between">
                            <h5>Servicios</h5>
                            <small>
                                <b-icon icon="chevron-double-right" class="h5"></b-icon>
                            </small>
                        </div>
                    </b-list-group-item>
                </b-list-group>            

                <b-list-group class="mb-1 view-customer-access-reports" v-if="accessExtras.INFORME_MARKETING.status" v-show="!isSeller">
                    <b-list-group-item href="#" class="flex-column align-items-start" @click="goReportMarketing()">
                        <div class="d-flex w-100 justify-content-between">
                            <h5>Marketing</h5>
                            <small>
                                <b-icon icon="chevron-double-right" class="h5"></b-icon>
                            </small>
                        </div>
                    </b-list-group-item>
                </b-list-group>

                <b-list-group class="mb-1 view-customer-access-reports" v-if="accessExtras.INFORME_AUDITORIA.status" v-show="!isSeller">
                    <b-list-group-item href="#" class="flex-column align-items-start" @click="goReportAudit()">
                        <div class="d-flex w-100 justify-content-between">
                            <h5>Auditoria</h5>
                            <small>
                                <b-icon icon="chevron-double-right" class="h5"></b-icon>
                            </small>
                        </div>
                    </b-list-group-item>
                </b-list-group>       
            </b-col>

        </b-row>     
    </div>
</template>
<script>
    import serviceAPI from './../services'
    import Error from '@/handler/error'
    import Session from '@/handler/session'
    import Profiles from '@/config/profiles'
    import Modules from '@/config/modules'
    import Helper from '@/handler/helper'    
    import moment from 'moment'

    export default {
        props: [
            'customers',
            'customersID',
            'reports'
        ],
        data() {
            return {
                arrReports: [],
                accessExtras:{
                  INFORME_VISITAS: {
                    module: Modules.INFORME_VISITAS,
                    status: false
                  }, 
                  INFORME_COBRANZAS: {
                    module: Modules.INFORME_COBRANZAS,
                    status: false
                  }, 
                  INFORME_NOVEDADES: {
                    module: Modules.INFORME_NOVEDADES,
                    status: false
                  },
                  INFORME_SERVICIOS: {
                    module: Modules.INFORME_SERVICIOS,
                    status: false
                  },
                  INFORME_MARKETING: {
                    module: Modules.INFORME_MARKETING,
                    status: false
                  },
                  INFORME_AUDITORIA: {
                    module: Modules.INFORME_AUDITORIA,
                    status: false
                  }                              
                },                
            }
        },
        computed: {
            isSeller(){
                if(Helper.getSeller()) {
                    return true
                } else {
                    return false
                }
            }  
        },
        created() {
          /* Configurar permisos extras para vincular modulos */
          this.accessExtras.INFORME_VISITAS.status = Helper.moduleEnabled(this.accessExtras.INFORME_VISITAS.module)
          this.accessExtras.INFORME_COBRANZAS.status = Helper.moduleEnabled(this.accessExtras.INFORME_COBRANZAS.module)
          this.accessExtras.INFORME_NOVEDADES.status = Helper.moduleEnabled(this.accessExtras.INFORME_NOVEDADES.module)
          this.accessExtras.INFORME_SERVICIOS.status = Helper.moduleEnabled(this.accessExtras.INFORME_SERVICIOS.module)
          this.accessExtras.INFORME_MARKETING.status = Helper.moduleEnabled(this.accessExtras.INFORME_MARKETING.module)
          this.accessExtras.INFORME_AUDITORIA.status = Helper.moduleEnabled(this.accessExtras.INFORME_AUDITORIA.module)
          /* Fin configuracion */
        },
        mounted() {
            this.loadReportsByCustomer()
        },
        methods: {
            loadReportsByCustomer() {        
                var result = serviceAPI.obtenerReportsByCustomers({
                    customers_id: this.customersID
                })

                result.then((response) => {
                    var data = response.data
                    this.arrReports = data                   
                })
            },
            setInfoToReports(type) {        
                var item = this.customers
                var result = "<span class='badge badge-danger view-customer-access-reports-widget-card-empty'>Sin Registros</span>"        
                
                if(item){                        
                    if(type=="news") {
                        if(item["last-reports-news"]) {
                            var infoResponsable = ''              
                            if(item["last-reports-news"].staff) {
                                infoResponsable = '<br><span class="view-customer-access-reports-widget-card-text-info">' + item["last-reports-news"].staff.name + ' - ' + moment(item["last-reports-news"].date).format('DD/MM/YYYY') + '</span>'
                            } else {
                                infoResponsable = '<br><span class="view-customer-access-reports-widget-card-text-info">' + item["last-reports-news"].seller.name + ' - ' + moment(item["last-reports-news"].date).format('DD/MM/YYYY') + '</span>'
                            }

                            result = "<span class='view-customer-access-reports-widget-card-text-primary'>" + moment.duration(moment().diff(moment(item["last-reports-news"].date),'days'),'days').humanize() + "</span>" + infoResponsable
                        }
                    }                

                    if(type=="services") {
                        if(item["last-reports-services"]) {
                            var infoResponsable = ''              
                            if(item["last-reports-services"].staff) {
                                infoResponsable = '<br><span class="view-customer-access-reports-widget-card-text-info">' + item["last-reports-services"].staff.name + ' - ' + moment(item["last-reports-services"].date).format('DD/MM/YYYY') + '</span>'
                            } else {
                                infoResponsable = ''
                            }

                            result = "<span class='view-customer-access-reports-widget-card-text-primary'>" + moment.duration(moment().diff(moment(item["last-reports-services"].date),'days'),'days').humanize() + "</span>" + infoResponsable
                        }
                    }    

                    if(type=="visits") {
                        if(item["last-reports-visits"]) {              
                            var infoResponsable = ''              
                            if(item["last-reports-visits"].staff) {
                                infoResponsable = '<br><span class="view-customer-access-reports-widget-card-text-info">' + item["last-reports-visits"].staff.name + ' - ' + moment(item["last-reports-visits"].date).format('DD/MM/YYYY') + '</span>'
                            } else {
                                infoResponsable = '<br><span class="view-customer-access-reports-widget-card-text-info">' + item["last-reports-visits"].seller.name + ' - ' + moment(item["last-reports-visits"].date).format('DD/MM/YYYY') + '</span>'
                            }

                            result = "<span class='view-customer-access-reports-widget-card-text-primary'>" + moment.duration(moment().diff(moment(item["last-reports-visits"].date),'days'),'days').humanize() + "</span>" + infoResponsable                
                        }
                    } 

                    if(type=="payments") {
                        if(item["last-reports-payments"]) {
                            var infoResponsable = ''              
                            if(item["last-reports-payments"].staff) {
                                infoResponsable = '<br><span class="view-customer-access-reports-widget-card-text-info">' + item["last-reports-payments"].staff.name + ' - ' + moment(item["last-reports-payments"].date).format('DD/MM/YYYY') + '</span>'
                            } else {
                                infoResponsable = '<br><span class="view-customer-access-reports-widget-card-text-info">' + item["last-reports-payments"].seller.name + ' - ' + moment(item["last-reports-payments"].date).format('DD/MM/YYYY') + '</span>'
                            }

                            result = "<span class='view-customer-access-reports-widget-card-text-primary'>" + moment.duration(moment().diff(moment(item["last-reports-payments"].date),'days'),'days').humanize() + "</span>" + infoResponsable
                        }                  
                    } 

                    if(type=="audit") {
                        if(item["last-reports-audit"]) {
                            var infoResponsable = ''              
                            if(item["last-reports-audit"].staff) {
                                infoResponsable = '<br><span class="view-customer-access-reports-widget-card-text-info">' + item["last-reports-audit"].staff.name + ' - ' + moment(item["last-reports-audit"].date).format('DD/MM/YYYY') + '</span>'
                            }

                            result = "<span class='view-customer-access-reports-widget-card-text-primary'>" + moment.duration(moment().diff(moment(item["last-reports-audit"].date),'days'),'days').humanize() + "</span>" + infoResponsable
                        }                  
                    } 

                    if(type=="marketing") {
                        if(item["last-reports-marketing"]) {
                            var infoResponsable = ''              
                            if(item["last-reports-marketing"].staff) {
                                infoResponsable = '<br><span class="view-customer-access-reports-widget-card-text-info">' + item["last-reports-marketing"].staff.name + ' - ' + moment(item["last-reports-marketing"].date).format('DD/MM/YYYY') + '</span>'
                            }

                            result = "<span class='view-customer-access-reports-widget-card-text-primary'>" + moment.duration(moment().diff(moment(item["last-reports-marketing"].date),'days'),'days').humanize() + "</span>" + infoResponsable
                        }                  
                    } 
                } 
                
                return result
            },
            goDetailReport(item, type) {        
                if(type=='VISITAS') {        
                    this.$router.push({ name: 'ReportsVisitsStaffDetailReport', params: {reportID: item.id} })
                }

                if(type=='COBRANZAS') {
                    this.$router.push({ name: 'ReportsPaymentStaffDetailReport', params: {reportID: item.id} })
                }

                if(type=='NOVEDADES') {
                    this.$router.push({ name: 'ReportsNewsStaffDetailReport', params: {reportID: item.id} })
                }

                if(type=='SERVICIOS') {
                    this.$router.push({ name: 'ReportsServicesStaffDetailReport', params: {reportID: item.id} })
                }  

                if(type=='AUDITORIA') {
                    this.$router.push({ name: 'ReportsAuditStaffDetailReport', params: {reportID: item.id} })
                }              

                if(type=='MARKETING') {
                    this.$router.push({ name: 'ReportsMarketingStaffDetailReport', params: {reportID: item.id} })
                }                   
            },
            goReportVisit() {
                if(this.isSeller) {
                    this.$router.push({ name: 'ReportsVisitsStaffCrudReportSellerByCustomers', params: {customersID: this.customersID} })
                } else {
                    this.$router.push({ name: 'ReportsVisitsStaffCrudReportByCustomers', params: {customersID: this.customersID} })
                }        
            },
            goReportPayment() {
                if(this.customers) {
                    if(this.isSeller) { 
                        this.$router.push({ name: 'ReportsPaymentStaffCrudReportSellerByCustomers', params: {customersID: this.customers.id + '|' + this.customers.code} })
                    } else {
                        this.$router.push({ name: 'ReportsPaymentStaffCrudReportByCustomers', params: {customersID: this.customers.id + '|' + this.customers.code} })
                    }          
                }        
            },
            goReportNews() {
                if(this.isSeller) { 
                    this.$router.push({ name: 'ReportsNewsStaffCrudReportSellerByCustomers', params: {customersID: this.customersID} })
                } else {
                    this.$router.push({ name: 'ReportsNewsStaffCrudReportByCustomers', params: {customersID: this.customersID} })
                }        
            },  
            goReportServices() {
                if(!this.isSeller) {                     
                    this.$router.push({ name: 'ReportsServicesStaffCrudReportByCustomers', params: {customersID: this.customersID} })
                }        
            },     
            goReportAudit() {
                if(!this.isSeller) {                     
                    this.$router.push({ name: 'ReportsAuditStaffCrudReportByCustomers', params: {customersID: this.customersID} })
                }        
            },     
            goReportMarketing() {
                if(!this.isSeller) {                     
                    this.$router.push({ name: 'ReportsMarketingStaffCrudReportByCustomers', params: {customersID: this.customersID} })
                }        
            },                       
        }   
    }
</script>
<style>

</style>