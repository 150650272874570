import { render, staticRenderFns } from "./courses.vue?vue&type=template&id=a0260a4a"
import script from "./courses.vue?vue&type=script&lang=js"
export * from "./courses.vue?vue&type=script&lang=js"
import style0 from "./courses.vue?vue&type=style&index=0&id=a0260a4a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports