<template>
    <b-row>
        <b-col>
            <b-form-group label="Motivos Adicionales">
              <b-form-select v-model="crud.form.reasons_additional" 
                             :options="arr.reasons" 
                             multiple 
                             :select-size="10"
                             :key="componentKey"
                             :disabled="isSeller">
              </b-form-select>

              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill mt-1" @click="crud.form.reasons_additional=[]">
                <i class="icon-refresh"></i>
                Borrar Selección
              </b-button>
            </b-form-group>                     
        </b-col>
            
        <b-col lg="12">            
            <b-button variant="dark" class="pull-right" size="sm" @click="save()" v-if="!isSeller">Guardar</b-button>
        </b-col>
    </b-row>
</template>
<script>
    import serviceAPI from './../services'
    import Error from '@/handler/error'
    import Session from '@/handler/session'
    import Profiles from '@/config/profiles'
    import Modules from '@/config/modules'
    import Helper from '@/handler/helper'    
    import FindObject from '@/components/inc/find/findObject'  

    export default {
      components: {
        FindObject,        
      },        
      props: [
        'customers',
        'customersID',
      ],
      data() {
        return {
          crud: {
            form: {
              id: 0,
              customers_id: 0,
              reasons_additional: [],                        
            }
          },
          arr: {
            reasons: []
          },
          componentKey: 0,
        }
      },
      watch: {        
        customers: function (newQuestion, oldQuestion) {
          this.load()
        }
      },      
      computed: {
        isSeller() {
          var seller = Helper.getSeller()
          if(seller) {
            return true
          } else {
            return false
          }
        },  
      },   
      mounted() {
        this.loadReasons()
      },
      methods: {
        load() {                              
          if(this.customers.reasons_additional){            
            this.crud.form.id = this.customers.reasons_additional.id,
            this.crud.form.customers_id = this.customers.reasons_additional.customers_id,                                                            
            this.crud.form.reasons_additional = this.customers.reasons_additional.reasons_additional.split(',')            
              
            this.forceRerender()        
          } else {
            this.crud.form.customers_id = this.customersID                    
          }          
        },
        loadReasons() {
          let loader = this.$loading.show();
          var result = serviceAPI.obtenerReason()

          result.then((response) => {
            var data = response.data

            data.forEach(element => {
              if(!element.available_all_customers) {
                this.arr.reasons.push({
                   value: element.id, 
                   text: element.reference + ' - ' + element.name
                })
              }              
            });            
            loader.hide()
          })
          .catch(error => {
            loader.hide()
            this.$awn.alert(Error.showError(error))
          });
        },
                    
        save() {            
          var typeOperation = ''
          var result = null
          let loader = this.$loading.show();                
            
          if(!this.crud.form.reasons_additional){
            typeOperation = 'delete'
            result = serviceAPI.eliminarReason(this.crud.form.id);
          } else {        
            if(this.crud.form.id) {
              typeOperation = 'edit'
              result = serviceAPI.editarReason(this.crud.form);
            } else {
              typeOperation = 'save'
              result = serviceAPI.agregarReason(this.crud.form);
            }                    
          }                
            
          result.then((response) => {                    
            loader.hide()
            this.$awn.success("Datos guardados con éxito");

            if(typeOperation != 'delete') {
              this.crud.form.id = response.data.id
            } else {
              this.crud.form.id = 0
            }
          })
          .catch(error => {
            loader.hide()
            this.$awn.alert(Error.showError(error));
          })            
        },    
        forceRerender() {
          this.componentKey += 1;  
        },             
      }
    }
</script>