<template>
  <b-row>
        
    <b-col sm="12">
      <h4 id="traffic" class="card-title mb-0">Cursos</h4>
      <div class="small text-muted">Cursos visualizados por el cliente</div>
    </b-col>    

    <b-col class="mt-3">
      <b-table class="mb-0"
              responsive
              head-variant="dark"
              :hover="true"
              :small="true"
              :fixed="true"
              :items="table.items"
              :fields="table.fields"              
              v-if="table.items.length">  

        <template v-slot:table-colgroup="scope">
          <col
            v-for="field in scope.fields"                    
            :key="field.key"
            :style="{ width: field.width }"
          >
        </template> 

        <template v-slot:cell(name)="data">
          <div class="crud-courses-title">
            <span v-if="data.item.course.code" class="crud-courses-code">
              {{data.item.course.code}} 
            </span><br>
            {{data.item.course.name}}
          </div>
        </template>

        <template v-slot:cell(image)="data">
          <b-img  :src="data.item.course.image[0]" 
                  fluid 
                  class="crud-course-image" 
                  v-if="data.item.course.image"/>
          <b-icon icon="image" 
                  v-else 
                  class="h2" 
                  v-b-tooltip.hover
                  title="Imagen No Disponible" />
        </template>      

        <template v-slot:cell(order_id)="data">
          <b># {{data.item.order_id}}</b>
        </template>
        
        <template v-slot:cell(paid)="data">          
          <div v-if="data.item.order.paid">
            <b-badge variant="success">Pagado</b-badge>
          </div>
          <div v-else>
            <b-badge variant="danger">Pendiente</b-badge>
          </div>
        </template>

      </b-table>
      <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
    </b-col>
  </b-row>
</template>
<script>
  export default {
    props: [
      'customers',
      'customersID',
    ],    
    data() {
      return {
        table : {
          items: [],
          fields: [                        
            {key: 'image', label: 'Imagen', width:"100px"},            
            {key: 'name', label: 'Nombre', width:"250px"},                        
            {key: 'paid', label: 'Pago', width:"50px", class:"align-middle text-center"},                        
            {key: 'order_id', label: 'Referencia Venta', width:"100px", class:"align-middle text-center"},            
            {key: 'f_action', label:'', width:"100px"},
          ],          
        },       
      }
    },
    watch: {        
      customers: function (newQuestion, oldQuestion) {
          this.load()
      }
    },    
    methods: {
      load() {
        if(this.customers.courses){
            this.table.items = this.customers.courses              
        } else {
            this.table.items = []
        }
      },      
    }        
  }
</script>
<style>
  .crud-courses-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-courses-code {
    color: gray;
  }
  .crud-course-image {
    height: 40px;
  }
</style>